<template>
  <div>
    <Breadcrumbs
      src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/top-banner-resources.jpg"
      breadcrumb="Resources"
      title="Resources"
    />
    <section id="content">
      <div class="section nobg nomargin">
        <div class="container">
          <div class="heading-block center">
            <h2>
              Everything You Need To Know About M-CHANGA Fundraising Features
              Explained:
            </h2>
          </div>
          <div class="tabs tabs-responsive justified-tabs tabs-resources">
            <ul class="tab-nav overflow-x-auto">
              <li
                class="cursor-pointer whitespace-nowrap px-3.5"
                v-on:click="toggleTabs(1)"
                v-bind:class="{
                  'bg-transparent text-gray-100': openTab !== 1,
                  'text-secondary bg-main': openTab === 1,
                }"
              >
                Summary Features
              </li>
              <li
                class="cursor-pointer whitespace-nowrap px-3.5"
                v-on:click="toggleTabs(2)"
                v-bind:class="{
                  'bg-transparent text-gray-100': openTab !== 2,
                  'text-secondary bg-main': openTab === 2,
                }"
              >
                Verification Features
              </li>
              <li
                class="cursor-pointer whitespace-nowrap px-3.5"
                v-on:click="toggleTabs(3)"
                v-bind:class="{
                  'bg-transparent text-gray-100': openTab !== 3,
                  'text-secondary bg-main': openTab === 3,
                }"
                style="display: none"
              >
                Personalization Features
              </li>
              <li
                class="cursor-pointer whitespace-nowrap px-3.5"
                v-on:click="toggleTabs(4)"
                v-bind:class="{
                  'bg-transparent text-gray-100': openTab !== 4,
                  'text-secondary bg-main': openTab === 4,
                }"
              >
                Sharing and Referrals
              </li>
            </ul>
            <div class="tab-container">
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 1,
                  block: openTab === 1,
                }"
              >
                <ResourceFeature
                  v-for="n in tab1"
                  :key="n"
                  :num="n.num"
                  :title="n.title"
                  :text="n.text"
                  :text2="n.text2"
                  :link="n.link"
                />
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 2,
                  block: openTab === 2,
                }"
              >
                <ResourceFeature
                  v-for="n in tab2"
                  :key="n"
                  :num="n.num"
                  :title="n.title"
                  :text2="n.text"
                  :text3="n.text2"
                  :link="n.link"
                />
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 3,
                  block: openTab === 3,
                }"
              >
                <ResourceFeature
                  v-for="n in tab3"
                  :key="n"
                  :num="n.num"
                  :title="n.title"
                  :text2="n.text"
                  :link="n.link"
                />
              </div>
              <div
                class="tab-content clearfix"
                v-bind:class="{
                  hidden: openTab !== 4,
                  block: openTab === 4,
                }"
              >
                <ResourceFeature
                  v-for="n in tab4"
                  :key="n"
                  :num="n.num"
                  :title="n.title"
                  :text2="n.text"
                  :link="n.link"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionBottom />
    </section>
    <Footer />
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import QuestionBottom from "../components/QuestionBottom.vue";
import ResourceFeature from "../components/resource-feature.vue";
import Footer from "../components/Footer/index.vue";
export default {
  components: {
    Breadcrumbs,
    QuestionBottom,
    ResourceFeature,
    Footer,
  },
  data() {
    return {
      openTab: 1,
      tab1: [
        {
          num: "1",
          title: "Online Registration",
          text: "Are you ready to enjoy simple, secure and convenient fundraising with M-CHANGA? Within minutes you will have a campaign ready to share to the world! Go to <a href='https://mchanga.africa/signup'>mchanga.africa/signup</a> to register and activate your fundraiser.",
        },
        {
          num: "2",
          title: "Editing your fundraiser info",
          text: "Fundraiser information can be edited at any time over the duration of your fundraiser. Do you want to edit your fundraiser name, target amount or expiry date? Sign in to your fundraiser and select <strong>Fundraiser Details</strong> on the menu.",
        },
        {
          num: "3",
          title: "Fundraiser statement",
          text: "With M-Changa your fundraiser,  donation, withdrawal and expense statements are available to you with a simple click! Sign in to your fundraiser and select <strong>My Statement</strong> on the menu.",
        },
        {
          num: "4",
          title: "Fundraiser media",
          text: "Add media to make your fundraiser message more powerful through video, images and a story! Sign in to your fundraiser and select <strong>Images, Video or Story</strong> on the menu.",
        },
        {
          num: "5",
          title: "Fundraiser patrons",
          text: "A patron's role is to advocate for your fundraiser and ensure that it meets its target. A patrons name, personal message and photo (optional) can be added to your fundraiser page. Sign in to your fundraiser and select <strong>Patron</strong> on the menu.",
        },
      ],
      tab2: [
        {
          num: "1",
          title: "Supporting documents",
          text: "M-Changa fundraisers require verification for due diligence. Are your fundraiser verification documents ready for upload? Sign in to your fundraiser and select <strong>Documents</strong> on the menu.",
        },
        {
          num: "2",
          title: "Treasurers",
          text: "Adding treasurers to fundraisers increases accountability and transparency. You can add up to three treasurers to help co-manage your fundraiser. Sign in to your fundraiser and select <strong>Treasurers</strong> on the menu.",
        },
        {
          num: "3",
          title: "Withdrawal",
          text: "You can make partial or full withdrawals of your funds as many times as you require over the course of your fundraiser. Sign in to your fundraiser and select <strong>Withdraw</strong> on the menu.",
        },
      ],
      tab3: [
        {
          num: "1",
          title: "Editing your fundraiser story",
          text: "Because we believe in the power of stories, here is a step by step guide highlighting how and where you can write your fundraising story;",
          link: "https://youtu.be/BcuwxdDLRJM",
        },
        {
          num: "2",
          title: "Adding images to your fundraiser",
          text: "Here is a step by step guide on adding images to your fundraiser;",
          link: "https://youtu.be/4IAhV74uv3Y",
        },
        {
          num: "3",
          title: "Adding a video to your fundraiser",
          text: "Here is a step by step guide on adding images to your fundraiser;",
          link: "https://youtu.be/sqjG3dUd-iQ",
        },
      ],
      tab4: [
        {
          num: "1",
          title: "Updating Donors (KE only)",
          text: "Would you wish to say thank you to your donors or maybe just send an SMS update to them? With M-Changa, you are provided 250 free SMS’s for this, the cost per SMS after this limit is KES 2 for an account with less than 1,000 donors, KES 1 for an account with 1,001 to 2,500 donors and 70 Cents for an account with 2,500 plus donors. Sign in to your fundraiser and select <strong>Update Donors</strong> on the menu (Kenya only).",
        },
        {
          num: "2",
          title: "Social sharing",
          text: "Sharing your fundraiser is always easy with M-Changa! Share to Facebook, Twitter and WhatsApp with a single click or share the link to your fundraiser page with anyone anywhere! Sign in to your fundraiser and select <strong>Share</strong> on the menu.",
        },
        {
          num: "3",
          title: "Would you like to become an M-Changa Africa affiliate?",
          text: "You can earn from referring M-Changa to your networks! Please contact our customer support at support@mchanga.africa or call +254 20 765 0919 to learn about the M-Changa Africa affiliate program!",
        },
      ],
    };
  },
  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>
