<template>
  <div class="resources-feature-item">
    <div class="feature-number">{{ num }}</div>
    <div class="feature-text">
      <h3 class="feature-title">{{ title }}</h3>
      <div class="feature-description">
        <p v-html="text"></p>
        <p v-html="text2"></p>
        <p v-html="text3"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    num: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    text2: {
      type: String,
    },
    text3: {
      type: String,
    },
    link: {
      type: String,
    },
  },
};
</script>
